import { BaseService } from '@/api/base.service';
import { APIConfiguration } from '@/api/index';
import {
  Configuration,
  FileResponse,
  QuestionControllerApiFactory,
  QuestionUpdateDto,
} from 'golem-api';
import { replaceDatesOfQuestion } from '@/utils/utils';

export class QuestionService extends BaseService {
  public controller = QuestionControllerApiFactory(new Configuration());

  public createId = async () => (await this.controller.postFakeQuestion()).data;

  public fav = async (questionId: string) => (await this.controller.favAQuestion(questionId)).data;

  public unfav = async (questionId: string) =>
    (await this.controller.unfavAQuestion(questionId)).data;

  public like = async (questionId: string) =>
    (await this.controller.voteForAQuestion(questionId)).data;

  public dislike = async (questionId: string) =>
    (await this.controller.unvoteAQuestion(questionId)).data;

  public getAllQuestions = async () => {
    const questions = (await this.controller.getAllQuestions()).data;
    questions.content?.forEach(replaceDatesOfQuestion);
    return questions;
  };

  public getQuestionById = async (questionId: string) => {
    const question = (await this.controller.getQuestion(questionId)).data;
    replaceDatesOfQuestion(question);
    return question;
  };

  public getQuestionByShortId = async (questionId: string) => {
    const question = (await this.controller.getQuestionViaShortId(questionId)).data;
    replaceDatesOfQuestion(question);
    return question;
  };

  public getQuestion = async (questionId: string) =>
    (questionId.length === 10 ? this.getQuestionByShortId : this.getQuestionById)(questionId);

  public getQuestions = async (page?: number, size?: number) => {
    const questions = (await this.controller.getAllQuestions(page, size)).data;
    questions.content?.forEach(replaceDatesOfQuestion);
    return questions;
  };

  public updateQuestion = async (id: string, question: QuestionUpdateDto) =>
    (await this.controller.putQuestion(id, question)).data;

  public getAttachment = async (articleId: string, attachmentId: string) =>
    (
      await this.controller.downloadAnAttachmentOfQuestion(articleId, attachmentId, {
        responseType: 'blob',
      })
    ).data;

  public uploadAttachments = async (
    articleId: string,
    attachments: File[],
  ): Promise<FileResponse[]> =>
    (await this.controller.uploadAttachmentsOfQuestion(articleId, attachments)).data;

  public removeAttachment = async (articleId: string, attachmentId: string) =>
    (await this.controller.deleteAnAttachmentOfQuestion(articleId, attachmentId)).data;
}

export default (apiConfiguration: APIConfiguration) => new QuestionService(apiConfiguration);
