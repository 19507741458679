import axios, { AxiosResponse } from 'axios';
import { getIdTokenSecret } from '@/utils/auth/useMsal';
import {
  DowncastConversionApi,
  DowncastDispatcher,
  DowncastWriter,
  ViewElement,
} from '@ckeditor/ckeditor5-engine';
import { Plugin } from '@ckeditor/ckeditor5-core';
import Item from '@ckeditor/ckeditor5-engine/src/model/item';

/*
code heavily based on this GitHub post : https://github.com/ckeditor/ckeditor5/issues/11745#issuecomment-1196324482
 */
type DataType = {
  attributeKey: string;
  item: unknown;
  attributeNewValue: string;
};

export class InjectAccessTokenPlugin extends Plugin {
  private replaceImage = (
    response: AxiosResponse,
    viewElement: ViewElement,
    viewWriter: DowncastWriter,
    data: DataType,
    img: ViewElement,
  ) => {
    const src = URL.createObjectURL(response.data);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    viewElement._children[0]._attrs.set('src', src);
    this.editor.editing.view.change(() => {
      viewWriter.setAttribute(data.attributeKey || '', src, img);
    });
  };

  private refetchImage = (url: string) =>
    axios.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${getIdTokenSecret()}`,
      },
    });

  init() {
    const editor = this.editor;
    const re = new RegExp(`${process.env.VUE_APP_BASE_API_PATH}.+`);

    editor.conversion.for('editingDowncast').add((dispatcher: DowncastDispatcher) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatcher.on<`attribute:src${string}`>(
        'attribute:src',
        (event: { name: string }, data: DataType, conversionApi: DowncastConversionApi) => {
          if (
            (event.name !== 'attribute:src:imageInline' &&
              event.name !== 'attribute:src:imageBlock') ||
            !data.attributeKey
          ) {
            return;
          }

          conversionApi.consumable.consume(data.item as Item, event.name);

          const viewWriter = conversionApi.writer;
          const viewElement: ViewElement | undefined = conversionApi.mapper.toViewElement(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data.item,
          );

          if (!viewElement) {
            console.warn('toViewElement failed', data.item);
            return;
          }

          let img = viewElement;
          if (viewElement.name !== 'img') {
            const tmp = viewElement.getChild(0);
            if (!tmp || !tmp.is('element') || tmp.name !== 'img') {
              console.warn('figure/span has no img', viewElement, tmp);
              return;
            }
            img = tmp;
          }

          const url = data.attributeNewValue;
          if (url) {
            if (re.exec(url)) {
              this.refetchImage(url)
                .then((response) => this.replaceImage(response, viewElement, viewWriter, data, img))
                .catch(console.error);
            } else {
              console.log('src is no api attachment url', url, process.env.VUE_APP_BASE_API_PATH);
            }
          } else {
            viewWriter.removeAttribute(data.attributeKey, img);
          }
        },
      ),
    );
  }
}
