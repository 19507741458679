import { BaseService } from '@/api/base.service';
import { APIConfiguration } from '@/api/index';
import { AnswerControllerApiFactory, AnswerResponse, Configuration } from 'golem-api';
import { replaceDatesOfAnswerVersion } from '@/utils/utils';

export class AnswerService extends BaseService {
  public controller = AnswerControllerApiFactory(new Configuration());

  public postAnswer = async (orignalPostId: string, content: string) => {
    const answerResponse = (
      await this.controller.postAnswer(orignalPostId, {
        version: { content },
      })
    ).data;
    if (answerResponse.currentVersion) {
      replaceDatesOfAnswerVersion(answerResponse.currentVersion);
    }
    return answerResponse;
  };

  public modifyAnswer = async (answerId: string, content: string) => {
    const answerResponse = (
      await this.controller.modifyAnswer(answerId, {
        version: { content },
      })
    ).data;
    if (answerResponse.currentVersion) {
      replaceDatesOfAnswerVersion(answerResponse.currentVersion);
    }
    return answerResponse;
  };

  public getAnswersOfPost = async (postId: string): Promise<AnswerResponse[]> => {
    const answers = (await this.controller.getAnswersOfQuestion(postId)).data;
    return answers;
  };

  public like = async (answerId: string) => (await this.controller.voteForAnAnswer(answerId)).data;

  public dislike = async (answerId: string) =>
    (await this.controller.unvoteAnAnswer(answerId)).data;

  public markAsAcceptedAnswer = async (answerId: string) =>
    this.controller.markAsAcceptedAnswer(answerId);

  public unmarkAsAcceptedAnswer = async (answerId: string) =>
    this.controller.unmarkAsAcceptedAnswer(answerId);
}

export default (apiConfiguration: APIConfiguration) => new AnswerService(apiConfiguration);
