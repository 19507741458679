import { BaseService } from '@/api/base.service';
import { APIConfiguration } from '@/api/index';
import { UserControllerApiFactory, Configuration, ArticleResponse } from 'golem-api';
import { noPhoto } from '@/api/error-handlers';
import { replaceDatesOfArticle } from '@/utils/utils';

export class UserService extends BaseService {
  public controller = UserControllerApiFactory(new Configuration());

  public isLikedArticle = async (articleId: string) =>
    (await this.controller.hasUserVotedArticles(articleId)).data;

  public isUserFavArticle = async (articleId: string) =>
    (await this.controller.isUserFavouriteArticle(articleId)).data;

  public isLikedQuestion = async (questionId: string) =>
    (await this.controller.hasUserVotedQuestions(questionId)).data;

  public isUserFavQuestion = async (questionId: string) =>
    (await this.controller.isUserFavouriteQuestion(questionId)).data;

  public getSelfPhoto = async (): Promise<File | void> =>
    this.controller
      .downloadConnectedUserPhoto({
        responseType: 'blob',
        cache: {
          ttl: 3600e3,
        },
      })
      .then((res) => res.data)
      .catch(noPhoto);

  public getAuthoredArticles = async (
    userId: string,
    preview?: boolean,
  ): Promise<ArticleResponse[]> => {
    const articles = (await this.controller.getUserArticles(userId, preview)).data;
    articles.forEach(replaceDatesOfArticle);
    return articles;
  };

  public getFavouriteArticles = async (preview?: boolean): Promise<ArticleResponse[]> => {
    const articles = (await this.controller.getUserFavouriteArticles(preview)).data;
    articles.forEach(replaceDatesOfArticle);
    return articles;
  };

  public getPhoto = async (userId: string) =>
    this.controller
      .downloadUserPhoto(userId, {
        responseType: 'blob',
        cache: {
          ttl: 3600e3,
        },
      })
      .then((res) => res.data)
      .catch(noPhoto);

  public getConnectedUser = async () => (await this.controller.getConnectedUserInfo()).data;

  public getUserById = async (userId: string) => (await this.controller.getUserInfo(userId)).data;

  public getUserByTrigram = async (trigram: string) =>
    (await this.controller.getUserInfoFromTrigram(trigram)).data;

  public getUser = async (key: string) =>
    (key.length === 3 ? this.getUserByTrigram : this.getUserById)(key);

  public getUserDraftedArticle = async (userId: string) => {
    return (await this.controller.getUserDraftedArticles(userId)).data;
  };
}

export default (apiConfiguration: APIConfiguration) => new UserService(apiConfiguration);
