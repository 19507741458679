import { CommentInteractionsResponse, EntityType } from 'golem-api';

export const enum EventType {
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
}

export const enum ArticleAction {
  archive = 'archive',
  post = 'post',
  update = 'update',
}

export const enum QuestionAction {
  post = 'post',
  update = 'update',
}

export type PostType = typeof EntityType.Article | typeof EntityType.Question;

export type MenuItem = 'articles' | 'questions' | 'users' | 'attachments' | 'answers';

export type ReactionsEmit = {
  (
    eventName: 'react',
    value: {
      commentId: string;
      reaction: string;
      onSuccess: (commentInteractionsResponse: CommentInteractionsResponse) => void;
    },
  ): void;
  (
    eventName: 'unreact',
    value: {
      commentId: string;
      onSuccess: () => void;
    },
  ): void;
};
