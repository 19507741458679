import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './styles/app.scss';
import { i18n } from '@/i18n';

// Vuetify
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { theme } from './theme';
import { msalPlugin } from '@/utils/auth/msalPlugin';
import { msalInstance } from './utils/auth/authConfig';
import { setupCache } from 'axios-cache-interceptor';
import axios from 'axios';
import { AxiosInstance } from 'golem-api/node_modules/axios/index';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const cachingAxios: AxiosInstance = setupCache(axios, {
  interpretHeader: false,
  cacheTakeover: true,
  ttl: 0,
});

const vuetify = createVuetify({
  theme,
  components,
  directives,
});

const app = createApp(App).use(msalPlugin, msalInstance).use(router).use(i18n).use(vuetify);
app.mount('#app');
