import { BaseService } from '@/api/base.service';
import { APIConfiguration } from '@/api/index';
import {
  CommentControllerApiFactory,
  CommentInteractionsResponse,
  CommentInteractionsResponseList,
  CommentResponse,
  Configuration,
} from 'golem-api';
import { replaceDatesOfComment } from '@/utils/utils';

export class CommentService extends BaseService {
  public controller = CommentControllerApiFactory(new Configuration());

  public postCommentOnArticle = async (articleId: string, content: string) => {
    const commentResponse = (
      await this.controller.postComment(articleId, {
        content,
      })
    ).data;
    replaceDatesOfComment(commentResponse);
    return commentResponse;
  };

  public postCommentOnAnswer = async (answerId: string, content: string) => {
    const commentResponse = (
      await this.controller.postCommentOnAnswer(answerId, {
        content,
      })
    ).data;
    replaceDatesOfComment(commentResponse);
    return commentResponse;
  };

  public getCommentsOfArticle = async (articleId: string): Promise<CommentResponse[]> => {
    const comments = (await this.controller.getCommentsOfArticle(articleId)).data;
    comments.forEach(replaceDatesOfComment);
    return comments;
  };

  public getCommentsOfAnswer = async (answerId: string): Promise<CommentResponse[]> => {
    const comments = (await this.controller.getCommentsOfAnswer(answerId)).data;
    comments.forEach(replaceDatesOfComment);
    return comments;
  };

  public unreactToACommentOfArticle = async (
    articleId: string,
    commentId: string,
  ): Promise<void> => {
    await this.controller.unreactToAComment(articleId, commentId);
  };

  public unreactToACommentOfAnswer = async (answerId: string, commentId: string): Promise<void> => {
    await this.controller.unreactToACommentOfAnswer(answerId, commentId);
  };

  public getReactionsOfArticle = async (
    articleId: string,
    commentId: string,
  ): Promise<CommentInteractionsResponseList[]> => {
    return (await this.controller.getCommentReactionsOfArticle(articleId, commentId)).data;
  };

  public getReactionsOfAnswer = async (
    answerId: string,
    commentId: string,
  ): Promise<CommentInteractionsResponseList[]> => {
    return (await this.controller.getCommentReactionsOfAnswer(answerId, commentId)).data;
  };

  public reactOnArticle = async (
    articleId: string,
    commentId: string,
    reaction: string,
  ): Promise<CommentInteractionsResponse> => {
    return (await this.controller.reactToAComment(articleId, commentId, { reaction })).data;
  };

  public reactOnAnswer = async (
    answerId: string,
    commentId: string,
    reaction: string,
  ): Promise<CommentInteractionsResponse> => {
    return (await this.controller.reactToACommentOfAnswer(answerId, commentId, { reaction })).data;
  };
}

export default (apiConfiguration: APIConfiguration) => new CommentService(apiConfiguration);
