import {
  AnswerVersionResponse,
  ArticleResponse,
  ArticleVersionResponse,
  CommentResponse,
  FileResponse,
  QuestionResponse,
  SearchResponse,
  TagResponse,
  UserResponse,
} from 'golem-api';
import compareDesc from 'date-fns/compareDesc';
import { useDisplay } from 'vuetify';

export const createObjectURL = (object: Blob) =>
  (window.URL || window.webkitURL).createObjectURL(object);

export const base64toBlob = (data: string, contentType: string): Blob => {
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
};

export const formattedDate = (date: Date, short?: boolean) => {
  if (short) {
    return date
      .toLocaleDateString('fr-FR', {
        month: 'short',
        day: 'numeric',
      })
      .replaceAll('/', ' ');
  } else {
    return date
      .toLocaleDateString('fr-CH', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replaceAll('/', '.');
  }
};

export const forceFileDownload = (file: Blob, title: string) => {
  const url = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', title);
  document.body.appendChild(link);
  link.click();
};

export const randomElementCount = (max: number) => Math.round(Math.random() * (max - 1) + 1);

export const arraysAreEqual = <T>(
  arrayA: T[],
  arrayB: T[],
  elementComparison: (elementA: T, elementB: T) => boolean,
) => {
  if (arrayA.length !== arrayB.length) {
    return false;
  }

  const comparisonArray = [...arrayB];

  return arrayA.every((elementA) => {
    const elementB = comparisonArray.shift();
    if (!elementA || !elementB) {
      return !elementA && !elementB;
    }

    return elementComparison(elementA, elementB);
  });
};

export const replaceDatesOfArticle = (article: ArticleResponse) => {
  article.originalCreationDate = new Date(article.originalCreationDate || Date.now());
  if (article.currentVersion && article.currentVersion) {
    article.currentVersion.createdAt = new Date(article.currentVersion.createdAt);
  }
  if (article.archivedAt) {
    article.archivedAt = new Date(article.archivedAt);
  }
};

export const replaceDatesOfArticleVersion = (articleVersion: ArticleVersionResponse) =>
  (articleVersion.createdAt = new Date(articleVersion.createdAt));

export const replaceDatesOfSearchResult = (result: SearchResponse) => {
  result.createdAt = new Date(result.createdAt);
};

export const replaceDatesOfQuestion = (question: QuestionResponse) => {
  question.originalCreationDate = new Date(question.originalCreationDate);
  if (question.currentVersion && question.currentVersion.createdAt) {
    question.currentVersion.createdAt = new Date(question.currentVersion.createdAt);
  }
};

export const replaceDatesOfComment = (comment: CommentResponse) => {
  comment.deletedAt = new Date(comment.deletedAt);
  comment.createdAt = new Date(comment.createdAt);
  comment.modifiedAt = new Date(comment.modifiedAt);
};

export const replaceDatesOfAnswerVersion = (answer: AnswerVersionResponse) => {
  answer.createdAt = new Date(answer.createdAt);
};

export const compareMostRecent = (a: ArticleResponse, b: ArticleResponse) => {
  if (!a.currentVersion && !b.currentVersion) {
    return 0;
  } else if (!a.currentVersion) {
    return -1;
  } else if (!b.currentVersion) {
    return 1;
  } else {
    return compareDesc(a.currentVersion.createdAt, b.currentVersion.createdAt);
  }
};

export const cleanTitle = (title: string) => (title.slice(0, 2) === '# ' ? title.slice(2) : title);

export const hasTitle = (text: string) => text.substring(0, 2) === '# ';

export const displayUserName = (user: UserResponse) =>
  useDisplay().mobile.value ? user.trigram : user.name;

export const tagsAsStrings = (tags: TagResponse[]) => (!tags ? [] : tags.map((tag) => tag.name));

export const attachmentsAsIds = (attachments: FileResponse[]) =>
  attachments.map((attachment) => attachment.id);
