import createMockService from './mock.service';
import createAnswersService from './answer.service';
import createArticlesService from './article.service';
import createCommentsService from './comment.service';
import createQuestionsService from './question.service';
import createSearchesServices from './search.service';
import createUserService from './user.service';
import {
  AnswerControllerApiFactory,
  ArticleControllerApiFactory,
  CommentControllerApiFactory,
  Configuration,
  QuestionControllerApiFactory,
  SearchControllerApiFactory,
  UserControllerApiFactory,
} from 'golem-api';
import { ref } from 'vue';
import { cachingAxios } from '@/main';

const Endpoints = {
  ARTICLES: '/articles',
  COMMENTS: '/comments',
  USERS: '/users',
};

const apiConfig = {
  ARTICLES: {
    GET: (id: string) => `${Endpoints.ARTICLES}/${id}`,
  },
};

const basePath = `${process.env.VUE_APP_BASE_API_PATH}`;

export type APIConfiguration = typeof apiConfig;

export const api = {
  answers: createAnswersService(apiConfig),
  articles: createArticlesService(apiConfig),
  comments: createCommentsService(apiConfig),
  mock: createMockService(apiConfig),
  questions: createQuestionsService(apiConfig),
  searches: createSearchesServices(apiConfig),
  users: createUserService(apiConfig),
};

let controllerConfiguration: Configuration = new Configuration();

export const isAppReady = ref(false);

export const setControllers = (accessToken: string) => {
  controllerConfiguration = new Configuration({
    accessToken,
  });

  api.answers.controller = AnswerControllerApiFactory(
    controllerConfiguration,
    basePath,
    cachingAxios,
  );
  api.articles.controller = ArticleControllerApiFactory(
    controllerConfiguration,
    basePath,
    cachingAxios,
  );
  api.comments.controller = CommentControllerApiFactory(
    controllerConfiguration,
    basePath,
    cachingAxios,
  );
  api.questions.controller = QuestionControllerApiFactory(
    controllerConfiguration,
    basePath,
    cachingAxios,
  );
  api.searches.controller = SearchControllerApiFactory(
    controllerConfiguration,
    basePath,
    cachingAxios,
  );
  api.users.controller = UserControllerApiFactory(controllerConfiguration, basePath, cachingAxios);
  isAppReady.value = true;
};

export type API = typeof api;

export default api;
