import { ref } from 'vue';
// suppress those lines because lib is using javascript so there is no type at all, and the import is breaking es-lint
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { EmojiIndex, EmojiData } from 'emoji-mart-vue-fast/src/utils/emoji-data.js';

const emoji_instance = ref();

const getInstance = (): EmojiService => {
  if (!emoji_instance.value) {
    emoji_instance.value = new EmojiService();
  }
  return emoji_instance.value;
};

export class EmojiService {
  private readonly emojiIndex: EmojiIndex;

  public constructor() {
    const data = require('emoji-mart-vue-fast/data/all.json');
    const custom: EmojiData[] = [
      {
        name: 'Amogus',
        short_names: ['sus'],
        text: '',
        emoticons: [],
        keywords: ['sus', 'among us', 'amogus', 'impostor'],
        custom: true,
        imageUrl: require('@/assets/amogus.png'),
      },
      {
        name: 'King',
        short_names: ['king'],
        text: '',
        emoticons: [],
        keywords: ['king', 'arr', 'emanuel'],
        custom: true,
        imageUrl: require('@/assets/king.jpg'),
      },
      {
        name: 'BMW',
        short_names: ['monseigneur'],
        text: '',
        emoticons: [],
        keywords: ['car', 'bmw', 'antoine'],
        custom: true,
        imageUrl: require('@/assets/monseigneur.jpg'),
      },
      {
        name: 'InTech',
        short_names: ['intech'],
        text: '',
        emoticons: [],
        keywords: ['intech', 'corpo', 'logo'],
        custom: true,
        imageUrl: require('@/assets/intech.png'),
      },
      {
        name: 'Chokbar',
        short_names: ['chokbar'],
        text: '',
        emoticons: [],
        keywords: ['chokbar', 'sanglier', 'bz'],
        custom: true,
        imageUrl: require('@/assets/chokbar.png'),
      },
    ];
    this.emojiIndex = new EmojiIndex(data, { custom });
  }

  public findEmoji = (short_name: string): EmojiData => {
    return this.emojiIndex.findEmoji(short_name);
  };

  public getIndex = () => {
    return this.emojiIndex;
  };

  public handleReactSound = (short_name: string) => {
    if (short_name === ':sus:') {
      const audioSus = new Audio(require('@/assets/sus.mp3'));
      audioSus.play().then();
    }
  };

  public handleUnreactSound = (short_name: string) => {
    if (short_name === ':sus:') {
      const audioKill = new Audio(require('@/assets/kill.mp3'));
      audioKill.play().then();
    }
  };
}

export default getInstance();
