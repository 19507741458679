import { createI18n } from 'vue-i18n';
import frFR from './frFR';

const messages = {
  'fr-FR': frFR,
};

export const i18n = createI18n({
  legacy: false,
  silentTranslationWarn: true,
  locale: 'fr-FR',
  fallbackLocale: 'fr-FR',
  messages,
});
