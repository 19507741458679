import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import AllArticles from '@/components/article/AllArticles.vue';
import ArticleView from '@/views/ArticleView.vue';
import ProfileView from '@/views/ProfileView.vue';
import QuestionView from '@/views/QuestionView.vue';
import AllQuestions from '@/views/AllQuestions.vue';
import SearchView from '@/views/SearchView.vue';
import ArticleComparisonView from '@/views/ArticleComparisonView.vue';

const DEFAULT_PAGE_NAME = 'GOLEM';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: HomeView,
    name: 'GOLEM',
  },
  {
    path: '/article/all/:page?',
    component: AllArticles,
  },
  {
    path: '/article/create',
    component: ArticleView,
  },
  {
    path: '/article/compare/:id',
    component: ArticleComparisonView,
  },
  {
    path: '/question/create',
    component: QuestionView,
  },
  {
    path: '/question/all/:page?',
    component: AllQuestions,
  },
  {
    path: '/question/:link/:edit(edit)?/:success(post|update)?',
    component: QuestionView,
  },
  {
    path: '/article/:link/:edit(edit)?/:success(post|update|archive)?',
    component: ArticleView,
  },
  {
    path: '/user/:userKey',
    component: ProfileView,
  },
  {
    path: '/search/:term',
    component: SearchView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = <string>(to.name || DEFAULT_PAGE_NAME);
  next();
});

export default router;
