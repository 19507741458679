import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';

const clientId = process.env.VUE_APP_GOLEM_CLIENT_ID;
const authority = process.env.VUE_APP_MSAL_AUTHORITY;
if (clientId === undefined) {
  throw new Error('no client ID provided');
}
if (authority === undefined) {
  throw new Error('no AAD authority provided');
}

const msalConfig: Configuration = {
  auth: {
    clientId,
    authority,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Error,
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
  forceRefresh: true,
};
