import { AccountInfo, InteractionStatus, PublicClientApplication } from '@azure/msal-browser';
import { getCurrentInstance, ref, Ref, toRefs, watch } from 'vue';
import process from 'process';

export type MsalContext = {
  instance: PublicClientApplication;
  accounts: Ref<AccountInfo[]>;
  inProgress: Ref<InteractionStatus>;
};

export function useMsal(): MsalContext {
  const internalInstance = getCurrentInstance();
  if (!internalInstance) {
    throw 'useMsal() cannot be called outside the setup() function of a component';
  }
  const { instance, accounts, inProgress } = toRefs(
    internalInstance.appContext.config.globalProperties.$msal,
  );

  if (!instance.value || !accounts.value || !inProgress.value) {
    throw 'Please install the msalPlugin';
  }

  if (inProgress.value === InteractionStatus.Startup) {
    instance.value.handleRedirectPromise().catch(() => {
      // Errors should be handled by listening to the LOGIN_FAILURE event
      return;
    });
  }

  return {
    instance: instance.value,
    accounts,
    inProgress,
  };
}

export const useIsAuthenticated = (): Ref<boolean> => {
  const { accounts } = useMsal();
  const isAuthenticated = ref(accounts.value.length > 0);

  watch(accounts, () => {
    isAuthenticated.value = accounts.value.length > 0;
  });

  return isAuthenticated;
};

export const getAccountKeys = () => {
  const accountKeys = localStorage.getItem('msal.account.keys');
  if (!accountKeys) {
    throw new Error('no account keys in local storage');
  }
  return JSON.parse(accountKeys);
};

export const getAccountData = () => {
  const accountData = localStorage.getItem(getAccountKeys()[0]);
  if (!accountData) {
    throw new Error('no account data in local storage');
  }
  return JSON.parse(accountData);
};

export const getTokenKeys = () => {
  const tokenKeys = localStorage.getItem(`msal.token.keys.${process.env.VUE_APP_GOLEM_CLIENT_ID}`);
  if (!tokenKeys) {
    throw new Error('no token keys in local storage');
  }
  return JSON.parse(tokenKeys);
};

export const getIdTokenKey = (tokenKeys: { idToken: string[] }) => tokenKeys.idToken[0];

export const getIdToken = (idTokenKey: string) => {
  const idToken = localStorage.getItem(idTokenKey);
  if (!idToken) {
    throw new Error('no id token in local storage');
  }
  return JSON.parse(idToken);
};
export const getIdTokenSecret = () => getIdToken(getIdTokenKey(getTokenKeys())).secret;

export const getIdTokenClaims = () => getAccountData().idTokenClaims;

export const getIdTokenExp = () => getIdTokenClaims().exp * 1e3;
export const getTimeOffset = () => getIdTokenExp() - Date.now();
export const isIdTokenExpired = () => getTimeOffset() <= 0;
export const isIdTokenExpiringSoon = (timeWindowMs = 300e3) => {
  return getTimeOffset() <= timeWindowMs;
};
