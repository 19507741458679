const shared = {
  add: 'Ajouter',
  add_file: 'Ajouter un fichier',
  article: '{n} article | {n} articles',
  archive: 'Archiver',
  attachment: 'Fichier joint | Fichiers joints ({n})',
  back: 'Retour',
  cancel: 'Annuler',
  close: 'Fermer',
  cover: 'Photo de couverture',
  download: 'Télécharger',
  draft_at: 'à',
  draft_button: 'Sauvegarder comme brouillon',
  draft_on: 'le',
  draft_sentence: "L'article que vous voyez est un brouillon fait par",
  edit: 'Éditer',
  error: 'Erreur',
  favourite: 'Favori',
  history: 'Historique',
  like: "J'aime",
  modified: 'Modifié le',
  publish: 'Publier',
  remove_like: "Je n'aime plus",
  see_all: 'Voir tout',
  see_less: 'Voir moins',
  send: 'Envoyer',
  success: 'Succès',
};

const error = {
  answer: {
    empty: 'Réponse vide.',
    generic: "Échec de l'envoi de la réponse.",
  },
  archiving: {
    generic: "Échec lors de l'archivage.",
  },
  article: {
    archived: 'Cet article a été archivé.',
    comparison: {
      no_other_version: "Cet article n'a aucune autre version.",
    },
    no_cover: "Cet article n'a pas d'image de couverture.",
    no_changes: 'Aucun changement à publier',
    not_found: 'Article introuvable',
    post: "Échec de la création de l'article.",
    update: "Échec de la mise à jour de l'article.",
  },
  comment: {
    empty: 'Commentaire vide',
    generic: "Échec de l'envoi du commentaire.",
  },
  profile: {
    not_found: 'Utilisateur introuvable',
    readonly: 'GOLEM accessible uniquement en consultation.',
  },
  publish: {
    no_changes: 'Aucun changement à publier',
    no_title: 'Titre non renseigné',
  },
  tags: {
    allowed_characters: 'Caractères autorisés : [a-z.-0-9]',
    already_selected: 'Un tag est déjà présent.',
    empty: 'Un tag ne peut pas être vide.',
    max_characters: '32 caractères maximum.',
  },
};

export default {
  answer: {
    emoji_reaction: 'Ajouter une réaction',
    most_liked: 'Meilleure réponse',
    prompt_add: 'Ajouter une réponse',
    prompt_edit: 'Modifier votre réponse',
    select: 'Sélectionner la réponse',
    selected: 'Sélectionnée par le rédacteur',
    title: 'Réponse | Réponses',
  },
  archiving: {
    title: 'Archivage',
    reason: "Motif de l'archivage",
    success: 'Article archivé',
    warning: 'Attention, il est impossible de désarchiver un article depuis Golem pour le moment.',
  },
  article: {
    all_articles: 'Liste des articles',
    editor: {
      add_tag: 'ajouter +',
      content_placeholder: "Contenu de l'article",
      title_placeholder: 'Titre',
    },
    post: {
      success: 'Article créé',
    },
    tags: 'tags',
    update: {
      success: 'Article mis à jour',
    },
  },
  comment: {
    emoji_reaction: 'Ajouter une réaction',
    hide: 'Masquer les commentaires',
    prompt: 'Ajouter un commentaire',
    title: 'Commentaire | Commentaires',
    show: 'Afficher les commentaires',
  },
  home: {
    app_meaning: 'Gatherer Of Learning, Experiences, Masteries',
    create_article: 'Écrire un article',
    create_question: 'Poser une question',
    featured: 'à la une',
    recent_articles: 'Articles',
    recent_questions: 'Questions',
  },
  menu: {
    answers: 'Réponses',
    articles: 'articles',
    attachments: 'Documents',
    home: 'accueil',
    questions: 'questions',
    users: 'Utilisateurs',
  },
  picker: {
    categories: {
      activity: 'Activité',
      custom: 'Personnalisé',
      flags: 'Drapeaux',
      foods: 'Nourriture & Boisson',
      nature: 'Animaux & Nature',
      objects: 'Objets',
      people: 'Personnes',
      places: 'Voyages & Lieux',
      recent: 'Récemment utilisés',
      search: 'Résultats de la recherche',
      smileys: 'Smileys & Émotions',
      symbols: 'Symboles',
    },
    notfound: "Pas d'emoji trouvé.",
    search: 'Rechercher',
    title: 'Choisissez votre emoji',
  },
  profile: {
    browse_articles: 'Parcourir les articles',
    button: {
      disconnect: 'Se déconnecter',
      my_profile: 'Mon profil',
    },
    no_articles: "Cet utilisateur n'a encore publié aucun article",
    no_favourites: "Tu n'as encore aucun article en favoris.",
    published_articles: 'Articles publiés',
    saved_articles: 'Articles en favoris',
  },
  question: {
    all_questions: 'Liste des questions',
    editor: {
      content_placeholder: 'Détails',
      title_placeholder: 'Question',
    },
    post: {
      success: 'Question créée',
    },
    update: {
      success: 'Question mise à jour',
    },
  },
  shared,
  error,
};
