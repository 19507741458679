import { ThemeDefinition } from 'vuetify';

export const golemTheme: ThemeDefinition = {
  dark: false,
  colors: {
    'surface': '#FFFFFF',
    'primary': '#F6771A',
    'secondary': '#DAD4FF',
    'background': '#FFF3EB',
    'info': '#17A6DB',
    'error': '#FF383F',
    'success': '#00A478',
    'warning': '#E1910E',
    'button-primary-hover': '#EF6719',
    'button-primary-active': '#E54D18',
  },
};

export const theme = {
  defaultTheme: 'golemTheme',
  themes: {
    golemTheme,
  },
};
