import { BaseService } from '@/api/base.service';
import { APIConfiguration } from '@/api/index';
import { Configuration, SearchControllerApiFactory, SearchResponse } from 'golem-api';
import { replaceDatesOfSearchResult } from '@/utils/utils';
import { emptyList } from '@/api/error-handlers';

export class SearchService extends BaseService {
  public controller = SearchControllerApiFactory(new Configuration());

  public search = async (term: string, numberOfResults?: number): Promise<SearchResponse[]> => {
    const results = await this.controller
      .search(term, numberOfResults)
      .then((res) => res.data)
      .catch(emptyList);
    results.forEach(replaceDatesOfSearchResult);
    return results;
  };
}

export default (apiConfiguration: APIConfiguration) => new SearchService(apiConfiguration);
