import { AxiosError, HttpStatusCode } from 'axios';
import { ErrorResponse } from 'golem-api';

export const noCoverArticle = () => console.warn('Article has no cover photo');

export const emptyList = () => [];

export const commentPostError = () => console.error('could not post comment');

export const answerPostError = () => console.error('could not post answer');

export const alreadyLiked = (error: AxiosError<ErrorResponse>) => {
  if (
    error.response?.data.statusCode === HttpStatusCode.BadRequest &&
    error.response?.data.message.includes('already')
  ) {
    console.warn('post is already liked');
  } else {
    console.error(error);
  }
};

export const alreadyDisliked = (error: AxiosError<ErrorResponse>) => {
  if (
    error.response?.data.statusCode === HttpStatusCode.BadRequest &&
    error.response?.data.message.includes('already')
  ) {
    console.warn("hasn't selected");
  } else {
    console.error(error);
  }
};

export const alreadyFav = (error: AxiosError<ErrorResponse>) => {
  if (
    error.response?.data.statusCode === HttpStatusCode.BadRequest &&
    error.response?.data.message.includes('already')
  ) {
    console.warn('article was already in favourites');
  } else {
    console.error(error);
  }
};

export const alreadyUnfav = (error: AxiosError<ErrorResponse>) => {
  if (
    error.response?.data.statusCode === HttpStatusCode.BadRequest &&
    error.response?.data.message.includes("hasn't selected")
  ) {
    console.warn('article was already removed from favourites');
  } else {
    console.error(error);
  }
};

export const noPhoto = (error: AxiosError<ErrorResponse>) => {
  if (error.response?.data.statusCode === HttpStatusCode.NotFound) {
    console.warn('User does not have a profile picture');
  } else {
    console.error(error);
  }
};
