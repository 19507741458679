import { BaseService } from '@/api/base.service';
import axios from 'axios';
import { APIConfiguration } from '@/api/index';
import {
  ArticleResponse,
  ArticleStatus,
  CommentResponse,
  EntityType,
  TagResponse,
  UserResponse,
} from 'golem-api';

export class MockService extends BaseService {
  // ms delay on mock api calls
  private fetchDelay = 600;

  // returns data with a delay
  private fetch = <T>(data: T): Promise<T> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(data);
      }, this.fetchDelay);
    });
  };

  /*****************************************************************************
   ********************************* ARTICLES *********************************
   ****************************************************************************/

  public archive = (articleId: string) =>
    this.fetch(this.article(articleId, ArticleStatus.Archived));

  private article = (
    id: string,
    archived: ArticleStatus = ArticleStatus.Visible,
  ): ArticleResponse => ({
    id: id,
    status: archived,
    folders: [{ id: 'test-id', name: 'public', url: '/public', level: 0 }],
    tags: [
      { id: 'gvhjn', name: 'svelte', color: '#DAD4FF' },
      { id: 'fghjkljbh', name: 'typescript', color: '#DAD4FF' },
      {
        id: 'fvgvbvnjv',
        name: 'ssr',
        color: '#DAD4FF',
      },
    ],
    currentVersion: {
      id: id,
      user: this.user(id),
      title: `Titre de l'article ${id}`,
      content: `Contenu **fascinant** de l'article.

_liste_ :

*   ab
    *   cd
        *   ef
    *   gh
    *   ij

\`\`\`cs
function exemple(param) {
\tConsole.WriteLine(param);
}
\`\`\`

---

> citation fameuse
> 
> \\--individu cité`,
      createdAt: new Date(),
    },
    originalAuthor: this.user(id),
    originalCreationDate: new Date(),
    attachments: [],
    numberOfComments: 0,
    numberOfLikes: 0,
    contentPreview: 'Preview',
    shortId: 'azertyuiop',
  });

  public getArticle = async (id: string): Promise<ArticleResponse> => this.fetch(this.article(id));

  public getRecentArticles = async (count: number): Promise<ArticleResponse[]> => {
    const articles = [];
    for (let i = 0; i < count; i++) {
      const a = await this.article(`test-article-${i}`);
      articles.push(a);
    }
    return this.fetch(articles);
  };

  private tag = async (): Promise<TagResponse> => ({
    id: 'test-tag-id',
    name: 'tag',
    color: '#DAD4FF',
  });

  public unarchive = (articleId: string) => this.fetch(this.article(articleId, 'VISIBLE'));

  /*****************************************************************************
   *********************************** USERS ***********************************
   ****************************************************************************/

  // returns the photo of the user with the specified ID
  public getPhoto = async (userId: string): Promise<Blob> =>
    this.fetch(
      // returns an image as binary data, just like microsoft graph api
      this.photo(userId),
    );

  private photo = async (userId: string): Promise<Blob> =>
    (
      await axios.get<Blob>(`https://cataas.com/cat/says/${userId}`, {
        responseType: 'blob',
      })
    ).data;

  // returns the data of the user with the specified ID
  public getUser = async (id: string): Promise<UserResponse> => this.fetch(this.user(id));

  private user = (id: string): UserResponse => ({
    id: id,
    name: 'Pierre Henselmann',
    trigram: 'PHN',
    photoId: `photo-id-${id}`,
  });

  /****************************************************************************
   **********************************COMMENTS**********************************
   ****************************************************************************/

  public getComment = async (): Promise<CommentResponse> => {
    return this.fetch(this.comment());
  };

  public getComments = async (count: number) => {
    const comments = [];
    for (let i = 0; i < count; i++) {
      comments.push(this.comment());
    }
    return this.fetch(comments);
  };

  public comment = (): CommentResponse => ({
    deletedAt: new Date(),
    id: 'test-id',
    modifiedAt: new Date(),
    postId: 'test-post-id',
    type: EntityType.Article,
    author: this.user('test-user-id'),
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    createdAt: new Date(Date.now() - Math.random() * 20e9),
  });
}

export default (apiConfiguration: APIConfiguration) => new MockService(apiConfiguration);
