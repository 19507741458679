import { App, reactive } from 'vue';
import {
  EventMessage,
  EventMessageUtils,
  EventType,
  InteractionStatus,
  PublicClientApplication,
  AccountInfo,
} from '@azure/msal-browser';
import { arraysAreEqual } from '@/utils/utils';

type AccountIdentifiers = Partial<
  Pick<AccountInfo, 'homeAccountId' | 'localAccountId' | 'username'>
>;

/**
 * Helper function to determine whether 2 account identifiers are equal
 * @param elementA
 * @param elementB
 */
const compareAccountIdentifiers = (elementA: AccountIdentifiers, elementB: AccountIdentifiers) => {
  return (
    elementA.homeAccountId === elementB.homeAccountId &&
    elementA.localAccountId === elementB.localAccountId &&
    elementA.username === elementB.username
  );
};

/**
 * Helper function to determine whether 2 arrays are equal
 * Used to avoid unnecessary state updates
 * @param arrayA
 * @param arrayB
 * @param elementComparison
 */
const accountArraysAreEqual = (
  arrayA: AccountIdentifiers[],
  arrayB: AccountIdentifiers[],
  elementComparison: (elementA: AccountIdentifiers, elementB: AccountIdentifiers) => boolean,
) => arraysAreEqual<AccountIdentifiers>(arrayA, arrayB, elementComparison);

export const msalPlugin = {
  install: (app: App, msalInstance: PublicClientApplication) => {
    const inProgress = InteractionStatus.Startup;
    const accounts = msalInstance.getAllAccounts();

    const state = reactive({
      instance: msalInstance,
      inProgress: inProgress,
      accounts: accounts,
    });

    app.config.globalProperties.$msal = state;

    msalInstance.addEventCallback((message: EventMessage) => {
      switch (message.eventType) {
        case EventType.ACCOUNT_ADDED:
        case EventType.ACCOUNT_REMOVED:
        case EventType.LOGIN_SUCCESS:
        case EventType.SSO_SILENT_SUCCESS:
        case EventType.HANDLE_REDIRECT_END:
        case EventType.LOGIN_FAILURE:
        case EventType.SSO_SILENT_FAILURE:
        case EventType.LOGOUT_END:
        case EventType.ACQUIRE_TOKEN_SUCCESS:
        case EventType.ACQUIRE_TOKEN_FAILURE: {
          const currentAccounts = msalInstance.getAllAccounts();
          if (!accountArraysAreEqual(currentAccounts, state.accounts, compareAccountIdentifiers)) {
            state.accounts = currentAccounts;
          }
          break;
        }
      }

      const status = EventMessageUtils.getInteractionStatusFromEvent(message, state.inProgress);
      if (status !== null) {
        state.inProgress = status;
      }
    });
  },
};
