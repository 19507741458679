import { BaseService } from '@/api/base.service';
import { APIConfiguration } from '@/api/index';
import {
  ArticleControllerApiFactory,
  ArticleCreateDto,
  ArticleStatus,
  ArticleVersionResponse,
  Configuration,
  FileResponse,
  PageArticleResponse,
  TagCreateDto,
  UserResponse,
} from 'golem-api';
import { replaceDatesOfArticle, replaceDatesOfArticleVersion } from '@/utils/utils';
import { noCoverArticle } from '@/api/error-handlers';

export class ArticleService extends BaseService {
  public controller = ArticleControllerApiFactory(new Configuration());

  public defaultArticlesCountPerPage = 15;

  public archive = async (articleId: string, archivingReason: string) =>
    (
      await this.controller.handleArticleArchiving(articleId, {
        status: ArticleStatus.Archived,
        archivingReason,
      })
    ).data;

  public createArticle = async (article: ArticleCreateDto, cover?: File) => {
    return this.controller.postArticle(article, cover).then((response) => response.data);
  };

  public createId = async () => (await this.controller.postFakeArticle()).data;

  public fav = async (articleId: string) => (await this.controller.favAnArticle(articleId)).data;

  public unfav = async (articleId: string) =>
    (await this.controller.unfavAnArticle(articleId)).data;

  public like = async (articleId: string) =>
    (await this.controller.voteForAnArticle(articleId)).data;

  public dislike = async (articleId: string) =>
    (await this.controller.unvoteAnArticle(articleId)).data;

  public getArticleById = async (articleId: string, isDraft?: boolean) => {
    const article = (await this.controller.getArticle(articleId, isDraft)).data;
    replaceDatesOfArticle(article);
    return article;
  };

  public getArticleByShortId = async (articleId: string, isDraft?: boolean) => {
    const article = (await this.controller.getArticleViaShortId(articleId, isDraft)).data;
    replaceDatesOfArticle(article);
    return article;
  };

  public getArticle = async (articleId: string, isDraft?: boolean) =>
    (articleId.length === 10 ? this.getArticleByShortId : this.getArticleById)(articleId, isDraft);

  public getArticleVersions = async (articleId: string): Promise<ArticleVersionResponse[]> => {
    const versions = (await this.controller.getArticleVersions(articleId)).data;
    versions.forEach(replaceDatesOfArticleVersion);
    return versions;
  };

  public getVersion = async (articleId: string, versionId: string) => {
    const version = (await this.controller.getASpecificVersion(articleId, versionId)).data;
    replaceDatesOfArticleVersion(version);
    return version;
  };

  public getAllArchivedArticles = async () => {
    const articles: PageArticleResponse = (
      await this.controller.getListOfArticle(ArticleStatus.Archived)
    ).data;
    articles.content?.forEach(replaceDatesOfArticle);
    return articles;
  };

  public getArticles = async (
    draft?: boolean,
    preview?: boolean,
    page?: number,
    size = this.defaultArticlesCountPerPage,
    sort?: string[],
  ) => {
    const articles = (
      await this.controller.getListOfArticle(
        ArticleStatus.Visible,
        draft,
        preview,
        page,
        size,
        sort,
      )
    ).data;
    articles.content?.forEach(replaceDatesOfArticle);
    return articles;
  };

  public getAttachment = async (articleId: string, attachmentId: string) =>
    (
      await this.controller.downloadAnAttachmentOfArticle(articleId, attachmentId, {
        responseType: 'blob',
      })
    ).data;

  public uploadAttachments = async (
    articleId: string,
    attachments: File[],
  ): Promise<FileResponse[]> =>
    (await this.controller.uploadAttachmentsOfArticle(articleId, attachments)).data;

  public removeAttachment = async (articleId: string, attachmentId: string) =>
    (await this.controller.deleteAnAttachmentOfArticle(articleId, attachmentId)).data;

  public getCover = async (articleId: string): Promise<Blob | void> =>
    this.controller
      .downloadCoverOfArticle(articleId, {
        responseType: 'blob',
        id: `${articleId}-cover`,
        cache: {
          ttl: 600e3,
        },
      })
      .then(
        (response) =>
          new Blob([response.data], {
            type: response.headers['Content-Type']?.toString(),
          }),
      )
      .catch(noCoverArticle);

  public updateCover = async (articleId: string, cover: File) => {
    return (
      await this.controller.uploadCoverOfArticle(articleId, cover, {
        cache: {
          update: {
            [`${articleId}-cover`]: 'delete',
          },
        },
      })
    ).data;
  };

  public updateTags = async (articleId: string, tags: TagCreateDto[]) => {
    await this.controller.uploadTagsOfArticle(articleId, tags);
  };

  public unarchive = async (articleId: string) =>
    (
      await this.controller.handleArticleArchiving(articleId, {
        status: ArticleStatus.Visible,
      })
    ).data;

  public updateArticle = async (id: string, article: ArticleCreateDto, cover?: File) =>
    (
      await this.controller.putArticle(
        id,
        article,
        cover,
        cover
          ? {
              cache: {
                update: {
                  [`${id}-cover`]: 'delete',
                },
              },
            }
          : {},
      )
    ).data;

  public getContributors = async (articleId: string): Promise<UserResponse[]> =>
    (await this.controller.getContributors(articleId)).data;
}

export default (apiConfiguration: APIConfiguration) => new ArticleService(apiConfiguration);
